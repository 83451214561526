import { isTablet as isTabletBrowser, isMobile as isMobileBrowser } from "react-device-detect";

export const isMobile = () => {
    return isMobileBrowser;
}

export const isTablet = () => {
    return isTabletBrowser;
}

export const isDesktop = () => !isTablet() && !isMobile();